@import "../../theme/variables.scss";

.top-signin-background {
  margin-top: 1em;
  padding: 0 !important;
  background-attachment: fixed !important;
  background-position: center !important;

  background-size: 100% !important;
  width: 100% !important;
  min-height: 750px !important;
  background-repeat: no-repeat !important;
  background-image: linear-gradient(
      rgba(255, 255, 255, 0.5),
      rgba(255, 255, 255, 0.5)
    ),
    url("/assets/img/hero_panel_secondary_header.jpg") !important;
  .signin-wrapper-container {
    padding-top: 8em;
    h2 {
      color: $purple-splash-dark !important;
      font-size: 3rem !important;
      font-weight: 400 !important;
      margin-bottom: 0.5em !important;
    }
    .middle-container {
      padding-top: 2em;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      height: 500px;
      align-items: center;
      .siginin-btn {
        border-radius: 5px;
        height: 23%;
        width: 40%;
        font-size: 23px;
        white-space: normal;
        margin-bottom: 2em;
      }
    }
  }
}

.signup-form-container {
  padding: 2.7em 0 4em 0;
  .ui.form .ui.primary.button.main-button {
    height: auto;
    margin: 0;
    line-height: 1em;
  }
  .ui.steps .step.active .title {
    color: #4d158a;
  }
  .ui.selection.dropdown > .delete.icon,
  .ui.selection.dropdown > .dropdown.icon,
  .ui.selection.dropdown > .search.icon {
    top: 16px;
  }
  h1 {
    font-size: 2rem !important;
    font-weight: 400 !important;
    margin-bottom: 0.5em !important;
  }
}

.text-right {
  text-align: right;
}
