.transparent-header {
  background-color: transparent;
  &.bg-color {
    background-image: linear-gradient(94deg, #3b1c5a, #374db1 163%);
  }
  .navbar {
    height: 95px;
  }
  .header-logo {
    padding: 0px;
    @media (max-width: 992px) {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
    }
  }
  .navbar-toggler {
    @media (max-width: 992px) {
      background-color: rgba(255, 255, 255, 0.15);
      padding: 0px;
      width: 35px;
      height: 35px;
      border: 0px;
      .navbar-toggler-icon {
        background-image: url("/assets/img/marketing/icons-menu.svg");
        width: 18px;
      }
    }
  }
  .header-left {
    @media (max-width: 992px) {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
    }
  }
  .navbar-dark {
    .navbar-nav {
      .nav-item {
        margin-left: 5px;
      }
      .nav-link {
        font-size: 14px;
        padding: 5px 15px 9px;
        color: #ffffff;
        border-radius: 3px;
        @media (max-width: 1200px) {
          padding: 5px 10px 9px;
        }
        &:hover {
          background-color: rgba(#ffffff, 0.1);
        }
        &.nav-active {
          background-color: rgba(#ffffff, 0.1);
        }
      }
      .nav-item {
        &:last-child {
          display: flex;
          .account-logo {
            border-radius: 3px;
            background-color: rgba(255, 255, 255, 0.15);
            display: flex;
            margin-right: 15px;
            align-items: center;
            justify-content: center;
            padding: 0px 15px;
            color: $white;
            width: 160px;
            span {
              padding: 0px;
              color: $white;
              font-size: 13px;
              font-weight: 800;
              overflow: hidden;
              text-overflow: ellipsis;
              text-transform: uppercase;
              &.text {
                color: #5e6267;
                font-size: 13px;
                padding: 0.5rem 1.5rem 0.5rem 0.5rem;
                font-weight: 500;
                text-transform: capitalize;
              }
            }
            .menu {
              width: 220px;
              padding: 20px 0px;
              right: 0px;
              left: auto;
              top: 55px;
              .item {
                display: flex;
                align-items: center;
                height: 35px;
                padding: 0px 20px 0px 40px !important;
                background-repeat: no-repeat;
                background-position: center left 20px;
                &.account-txt {
                  background-image: url("/assets/img/marketing/icons-user.svg");
                }
                &.renter-txt {
                  background-image: url("/assets/img/marketing/icons-home-header.svg");
                }
                &.logout-txt {
                  background-image: url("/assets/img/marketing/icons-sign-out.svg");
                }
              }
            }
          }
        }
        &.dropdown {
          .dropdown-menu {
            padding: 20px 0px;
            width: 220px;
            // height: 140px;
            top: 40px;
            .dropdown-item {
              font-size: 13px;
              font-weight: normal;
              height: 35px;
              padding: 0px 20px;
              display: flex;
              align-items: center;
              color: #5e6267;
            }
          }
        }
      }
    }
  }
  .dropdown-toggle {
    // margin-right: 50px;
    @media (max-width: 1200px) {
      margin-right: 0px;
    }
    &::after {
      content: "";
      display: none;
    }
  }
  .dropdown-menu {
    padding: 1rem 0;
  }
  .dropdown-item {
    font-size: 14px;
    padding: 0.5rem 3.5rem 0.5rem 1.5rem;
    &:focus,
    &:hover,
    &:active {
      background-color: transparent;
      color: unset;
    }
    &:hover {
      background-color: rgba(0, 0, 0, 0.05);
    }
    &.active {
      background-color: transparent;
      color: unset;
    }
  }
  .mobile-close-menu {
    display: none;
  }
  @media (max-width: 992px) {
    .collapse-menu {
      background-color: #fff;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      padding: 40px;
      transition: transform 0.3s linear;
      left: -100%;
      .navbar-nav {
        .nav-item {
          margin-left: 0px;
          height: 50px;
          .nav-link {
            height: 100%;
            color: #171a1d;
            display: flex;
            align-items: center;
            padding: 0px 20px !important;
            &:hover,
            &.nav-active {
              background-image: linear-gradient(99deg, #3b1c5a, #374db1 163%);
              color: #fff !important;
            }
          }
          button {
            background-color: rgba(23, 26, 29, 0.15);
            width: 140px;
            height: 45px;
            margin-top: 20px;
            color: #171a1d;
            & + button {
              margin-left: 15px;
            }
          }
        }
      }
    }
    .mobile-close-menu {
      display: block;
      width: 35px;
      height: 35px;
      border-radius: 3px;
      background-color: #3b3c3f;
      margin-left: auto;
      margin-bottom: 38px;
    }
  }
  &.active-menu {
    @media (max-width: 992px) {
      .collapse-menu {
        transform: translateX(100%);
        transition: transform 0.3s linear;
      }
    }
  }
}
