@media (max-width: 768px) {
  [class*="col-"] {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .home-widgets {
    padding-top: 100px !important;
    .col-6 {
      -ms-flex: 0 0 50%;
      flex: 0 0 50%;
      max-width: 50%;
    }
  }
}
.mxw-120 {
  max-width: 120px;
}
