
.ui.segment .property-detail-wrapper{
    padding-bottom: 3em;
    box-shadow: none;
    border: 0;
    .ui.five.cards>.card {
        width: calc(20% - 1.5em) !important;
    }
    .ui.card .content .header.unit-card-header {
        margin-bottom: 5px;
        font-family: 'Be Vietnam';
        font-size: 14px !important;
        font-weight: 600;
        color: #171a1d;
        padding-top: 0;
    }
    .property-img{
        max-height: 450px;
        object-fit: cover;
        border-radius: 3px;
    }
    .ui.grid{
        justify-content: center;
    }
    .property-images-row{
        padding-top: 0;
        & > div{
            margin-left: -15px;
            margin-right: -15px;
            padding: 0;
        }
        .carousel-inner{
            .carousel-item{
                max-height: 450px;
            }
        }
    }
}
// Carousel Style
.carousel-inner{
    .carousel-item{
        width: 100%;
        img{
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
    }
}
.carousel-control-prev, .carousel-control-next{
    width: 50px;
    opacity: 1;
    .carousel-control-prev-icon{
        background-image: url("/assets/img/carousel-arrow-left.svg");
        width: 35px;
        height: 35px;
        border-radius: 50%;
        background-color: #16191d80;
        padding: 5px;
        background-size: auto;
    }
    .carousel-control-next-icon{
        background-image: url("/assets/img/carousel-arrow-right.svg");
        width: 35px;
        height: 35px;
        border-radius: 50%;
        background-color: #16191d80;
        padding: 5px;
        background-size: auto;
    }
}
.semanticModal {
  top: unset !important;
  right: unset !important;
  bottom: unset !important;
  left: unset !important;
  overflow-y: auto;
}
.tourRequestModal {
  height: 475px;
  .content {
    max-height: 531px;
    overflow-y: auto;
  }
  select {
    //   background:red !important;
  }
  .form-control {
    height: 50px !important;
    padding: 0px;
    line-height: 30px;
    border: 1px solid #e2e2e2;
    outline: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    // min-height: 100px;
    resize: none;
    // line-height: 26px;
  }
  .rld-single-input {
    position: relative;
    input {
      height: 48px;
      line-height: 48px;
      width: 100%;
      padding: 0 20px;
      border: 1px solid #d4d4d4;
      // @include transition(all, 0.4s, ease);
      border-radius: 4px;
      font-size: 15px;
      &:hover,
      &:active,
      &:focus {
        border: 1px solid var(--main-color-one);
      }
    }
  }
}

.property-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 20%;
}
.tour-form {
  &-container {
    height: 550px;
    width: 500px;
    position: fixed;
    // bottom: 250px;
    background-color: grey;
    right: 50px;
    border-radius: 20px;
    background-color: white;
    z-index: 40;
  }
}
.contact-form-wrap {
  background: white;
  border-radius: 10px;
  padding: 30px;
  // box-shadow: $box-shadow;
  .rld-single-input {
    margin-top: 10px;
  }
  button {
    margin-top: 23px;
    padding: 0 45px;
    letter-spacing: 0.5px;
  }
  .social-icon {
    margin-top: 20px;
  }
}
.DayPickerInput {
  input {
    background: #f3f3f3 !important;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    width: 103%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
  }
  &-OverlayWrapper {
    padding-top: 80px !important;
  }
}

.detail-cards {
  .meta {
    padding-top: 0 !important;
  }
}

.button-group-container {
  margin-top: 10% !important;
  .ui.buttons .button {
    border-top-right-radius: 0;
    border-top-left-radius: 0;
  }
}

.mt-12 {
  margin-top: 12px !important;
}

.mt-20 {
  margin-top: 20px !important;
}

.mt-45 {
  margin-top: 45px !important;
}

.mt-1em {
  margin-top: 1em;
}

.mb-1em {
  margin-bottom: 1em;
}

.property-details-map {
    padding: 20px;
    border-radius: 3px;
    box-shadow: 0 0px 5px 2px #e5eced;
    background-color: #fff;
    margin-top: 10px;
    .property-location-label{
        font-size: 15px;
        font-weight: bold;
        color: #171a1d;
        margin-bottom: 20px;
    }
    > div {
        // height: 100% !important;
    }
    &.booking-detail-map{
        flex-grow: unset !important;
        > div{
            height: 200px !important;
        }
        .property-location-label{
            height: auto !important;
        }
    }
}
.room-total-wrap{
    color: #171a1d;
    margin-top: 66px;
    .total-room-count{
        font-size: 11px;
    }
    .total-room-price{
        font-size: 20px;
    }
    .total-room-tax{
        font-size: 11px;
    }
    .payment-terms-list{
        margin-top: 20px;
        padding-left: 15px;
        margin-bottom: 20px;
        li{
            font-size: 11px;
            color: #171a1d;
            margin-bottom: 7px;
            &::marker{
                color: #d8d8d8;
                font-size: 10px;
            }
        }
    }
}

.booking-detail-layout{
    background-color: #f8f8f8 !important;
    .property-detail-wrapper{
        background-color: #f8f8f8 !important;
    }
}

.property-detail-layout {
  margin-top: 95px !important;
  .property-information {
    .property-title {
      font-size: 25px;
      font-weight: bold;
      color: #171a1d;
    }
    .property-description{
        font-size: 14px;
        line-height: 2.14;
        color: #171a1d;
        letter-spacing: normal;
        .description-title{
            font-size: 13px;
            color: #5e6267;
        }
        pre {
          max-width:1259px !important;
          overflow-wrap: break-word;
          word-wrap: break-word;
          white-space: pre-wrap;
        }
      }
    }
    .property-contact-row {
      display: flex;
      padding: 25px 30px;
      border-radius: 3px;
      background-color: #0000000d;
      margin-top: 25px;
      .property-contact-col {
        width: 50%;
        .contact-label {
          font-size: 12px;
          color: #5e6267;
        }
        .contact-info {
          font-weight: 500;
          color: #171a1d;
        }
      }
    }
  }
  .property-description {
    font-size: 14px;
    line-height: 2.14;
    color: #171a1d;
    letter-spacing: normal;
    pre {
      max-width: 1259px !important;
      overflow-wrap: break-word;
      word-wrap: break-word;
      white-space: pre-wrap;
    }
  }
  .property-services-row {
    padding: 20px 30px;
    border-radius: 3px;
    box-shadow: 0 0px 5px 2px #e5eced;
    background-color: #fff;
    margin-top: 20px;
    .property-services-title {
      color: #5e6267;
      margin-bottom: 15px;
    }
    .services-list {
      .services-list-item {
        padding: 10px 20px 15px;
        border-radius: 3px;
        border: solid 1px #dedede;
        background-color: #f8f8f8;
        margin-bottom: 10px;
        .services-label {
          font-size: 13px;
          font-weight: bold;
          color: #171a1d;
          margin-bottom: 2px;
        }
        .services-description {
          font-size: 12px;
          line-height: 1.67;
          color: #5e6267;
        }
      }
    }
}

.book-now-btn{
    height: 45px;
    width: 146px;
    margin-top: 12px;
}

.property-price-card.ui.card{
    margin-top: -80px !important;
    padding: 25px 25px 20px !important;
    border-radius: 4px !important;
    background-image: linear-gradient(116deg, #3b1c5a, #374db1 161%) !important;
    box-shadow: none !important;
    flex-grow: 0 !important;
    margin-bottom: 30px;
    .price-label{
        font-weight: 500;
        color: #ffffffb3;
    }
    .basic-info-row {
      margin: 0 -8px 20px;
      display: flex;
      .basic-info-col {
        width: 33.33%;
        margin: 0 8px 15px;
        .info-description {
          color: #171a1d;
        }
      }
    }
    .info-label {
      font-size: 13px;
      color: #5e6267;
      margin-bottom: 5px;
    }
    .amenities-row {
      margin: 0 -5px;
      display: flex;
      flex-wrap: wrap;
      .amenities-col {
        margin: 5px;
        padding: 8px 20px 10px;
        border-radius: 3px;
        border: solid 1px #dedede;
        background-color: #f8f8f8;
      }
    }
  }

.property-price-card.ui.card {
  margin-top: -80px !important;
  padding: 25px 25px 20px !important;
  border-radius: 4px !important;
  background-image: linear-gradient(116deg, #3b1c5a, #374db1 161%) !important;
  box-shadow: none !important;
  flex-grow: 0 !important;
  margin-bottom: 30px;
  .price-label {
    font-weight: 500;
    color: #ffffffb3;
  }
  .price-detail {
    font-size: 20px;
    font-weight: bold;
    color: #fff;
    .divider {
      margin: 4px 15px 4px;
      opacity: 0.4;
      color: #fff;
      font-size: 16px;
    }
  }
  .tour-btn {
    button {
      height: 45px;
      font-size: 13px;
      font-weight: 800;
      text-align: center;
      color: #171a1d;
      text-transform: uppercase;
    }
  }
  button {
    border-radius: 3px !important;
  }
}

.property-units-wrapper {
  padding-right: 12px;
  .ui.card {
    .image {
      height: 150px;
      img {
        height: 100% !important;
        object-fit: cover;
      }
    }
    .content {
      padding: 15px !important;
      .prop-unit-info {
        padding-top: 0;
        margin-bottom: 15px;
        display: flex;
        justify-content: space-between;
        // align-items: center;
        strong {
          font-size: 13px;
          font-weight: 500;
          color: #3b1c5a;
        }
        .seperator {
          min-width: 4px;
          height: 4px;
          background-color: #6b66ff4d;
          border-radius: 50%;
          margin: 10px;
        }
      }
      .main-button {
        width: 100% !important;
        border-radius: 3px;
        background-color: #3b1c5a1a;
        font-size: 13px;
        font-weight: 800;
        text-align: center;
        color: #3b1c5a !important;
        text-transform: uppercase;
        font-family: "Be Vietnam";
      }
    }
  }
}

// Booking detail style
.booking-table-wrap{
    .booking-table-head{
        display: flex;
        align-items: center;
        border-radius: 3px;
        background-color: #5e62671a;
        .table-head-col{
            display: flex;
            &:nth-child(1){
                width: 40%;
            }
            &:nth-child(2){
                width: 60%;
            }
            .booking-head-list{
                font-size: 12px;
                color: #5e6267;
                flex: 1 1 auto;
                padding: 14px 20px 18px;
                &:nth-child(1){
                    width: 10%;
                }
                &:nth-child(2){
                    width: 20%;
                }
                &:nth-child(3){
                    width: 20%;
                }
                &:nth-child(4){
                    width: 10%;
                }
            }
        }
    }
    .booking-table-row{
        border-radius: 3px;
        box-shadow: 0 0px 5px 2px #e5eced;
        background-color: #fff;
        margin-top: 20px;
        display: flex;
        .booking-table-col{
            &:nth-child(1){
                width: 40%;
                padding: 30px 20px 20px;
                border-right: 1px solid #5e62671a;
            }
            &:nth-child(2){
                width: 60%;
                .room-table-row{
                    display: flex;
                    width: 100%;
                    .booking-body-list{
                        padding: 30px 20px 20px;
                        flex: 1 1 auto;
                        border-left: 1px solid #5e62671a;
                        border-bottom: 1px solid #5e62671a;
                        &:nth-child(1){
                            width: 100px; // 20px icon max 5 icon
                            border-left: 0;
                        }
                        &:nth-child(2){
                            width: 20%;
                        }
                        &:nth-child(3){
                            width: 20%;
                        }
                        &:nth-child(4){
                            width: 10%;
                        }
                        &.price-col{
                            .price-title{
                                font-size: 15px;
                                font-weight: bold;
                                color: #171a1d;
                            }
                            .price-subtitle{
                                font-size: 12px;
                                color: #5e6267;
                                margin-top: 4px;
                            }
                        }
                        &.booking-choices{
                            ul{
                                padding-left: 15px;
                                li{
                                    font-size: 12px;
                                    color: #171a1d;
                                    margin-bottom: 7px;
                                    &::marker{
                                        color: #d8d8d8;
                                        font-size: 10px;
                                    }
                                    &.text-success{
                                        color: #11ae7d !important;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .room-title{
                font-size: 16px;
                font-weight: 600;
                color: #3b1c5a !important;
                text-decoration: underline;
                margin-bottom: 10px;
                cursor: pointer;
                display: inline-block;
            }
            .room-subtitle{
                font-size: 14px;
                font-weight: bold;
                color: #2b7ca6;
                margin-bottom: 10px;
            }
            .room-amenities-list{
                margin: 0 -5px;
                display: flex;
                flex-wrap: wrap;
                .amenities-list-item{
                    font-size: 12px;
                    font-weight: 500;
                    border-radius: 3px;
                    border: solid 1px #dedede;
                    background-color: #f8f8f8;
                    padding: 7px 20px 9px;
                    margin: 0 5px 10px;
                }
            }
        }
    }
}
.room-facilities-list{
    margin: 0 -5px;
    display: flex;
    flex-wrap: wrap;
    .facilities-list-item{
        font-size: 13px;
        color: #171a1d;
        margin: 0 5px 10px;
        display: flex;
        align-items: center;
        &::before{
            content: '';
            height: 20px;
            width: 20px;
            background-image: url("/assets/img/check-icon.svg");
            background-size: contain;
            margin-right: 5px;
            margin-top: 5px;
        }
    }
}

h2{
    font-size: 22px !important;
    font-weight: bold !important;
    font-stretch: normal;
    font-style: normal;
    line-height: normal !important;
    letter-spacing: normal;
    color: #171a1d;
}
.property-rules-wrap{
    border-radius: 3px;
    box-shadow: 0 11px 14px -10px #e5eced;
    flex-grow: unset !important;
    .property-rules-list{
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 25px 20px;
        background-color: #fff;
        border-bottom: 1px solid #5e62671a;
        &:last-child{
            border-bottom: 0;
        }
        .rule-title{
            font-size: 15px;
            font-weight: 600;
            color: #171a1d;
        }
        .rule-subtitle{
            font-size: 12px;
            color: #5e6267;
        }
        .rule-timing{
            font-size: 15px;
            font-weight: 600;
            color: #171a1d;
            margin-left: 15px;
            white-space: nowrap;
        }
        .rule-icon{
            margin-right: 10px;
            width: 25px;
            min-width: 25px;
            height: 25px;
        }
    }
}

.property-print-wrap{
    .property-print-card{
        border-radius: 3px;
        box-shadow: 0 11px 14px -10px #e5eced;
        background-color: #fff;
        font-size: 12px;
        line-height: 1.67;
        letter-spacing: normal;
        color: #5e6267;
        padding: 20px;
        height: calc(100% - 40px);
        overflow-y: auto;
        max-height: 705px;
        // Custom Scrollbar
        &::-webkit-scrollbar {
            width: 6px;
        }
        &::-webkit-scrollbar-track {
            background-color: transparent;
        }
        &::-webkit-scrollbar-thumb {
            background: #ddd;
            border-radius: 3px;
        }
    }
}

// Room Detail Modal
.roomDetailModal{
    padding: 50px 50px 40px;
    background-color: #f8f8f8 !important;
    .close.icon{
        top: 0 !important;
        right: 0 !important;
        color: #171a1d !important;
    }
    .content{
        padding: 0 !important;
        background-color: #f8f8f8 !important;
    }
    .carousel-inner{
        .carousel-item{
            max-height: 230px;
        }
    }
    .room-title{
        font-size: 22px;
        font-weight: 800;
        color: #171a1d;
        margin-top: 20px;
    }
    .room-subtitle{
        font-size: 15px;
        line-height: 1.6;
        color: #5e6267;
        margin-top: 5px;
    }
    .room-amenities-list{
        margin: 10px -5px 0;
        display: flex;
        flex-wrap: wrap;
        .amenities-list-item{
            padding: 8px 20px 10px;
            margin: 0 5px 10px;
            border-radius: 3px;
            border: solid 1px #dedede;
            background-color: #f8f8f8;
            font-size: 12px;
            font-weight: 500;
            color: #181b20;
        }
    }
    .room-description{
        font-size: 15px;
        line-height: 1.6;
        color: #5e6267;
        margin-top: 5px;
    }
    .room-bathroom-label{
        font-size: 15px;
        color: #171a1d;
        margin: 20px 0 10px;
    }
    .room-services-list{
        margin-top: 20px;
        display: flex;
        flex-wrap: wrap;
        .services-list-item{
            padding-right: 50px;
            .services-label{
                font-size: 12px;
                color: #5e6267;
            }
            .services-title{
                font-size: 15px;
                color: #171a1d;
            }
        }
    }
}

