@import "../../theme/variables.scss";

.SearchBox {
  input {
    font-size: 16px;
    color: $grey-light-text;
    border-radius: 4px;
    &:focus {
      box-shadow: 0 0 0 2px rgba(1, 199, 170, 0.2);
      border: 0;
      outline: 0;
    }
  }
  i {
    display: none;
  }

  &__with_icon {
    input {
      padding-left: 50px;
    }
    i {
      display: block;
      z-index: 2;
      top: 12px;
      left: 30px;
      font-size: 24px;
      position: absolute;
    }
  }
}

:global(.pac-container) {
  height: unset;
  margin-top: 10px;
}

:global(.pac-item) {
  color: $grey-light-text;
  line-height: 32px;
  height: 36px;
}

:global(.pac-icon) {
  top: 3px;
}

:global(.pac-item-query) {
  font-size: 16px;
  color: $grey-main;
}

:global(.pac-container:after) {
  display: none;
}
