@import "./common.scss";
@import "./variables.scss";
@import "./mixins.scss";
@import "./base.scss";
@import "./button.scss";
@import "./header.scss";
@import "./footer.scss";
@import "./modal.scss";
@import "./service-provider.scss";
@import "./form.scss";
@import "./signIn.scss";
@import "./signup.scss";
@import "./home.scss";
@import "./local-site.scss";
@import "./faqs.scss";
@import "/about.scss";
@import "./contact.scss";
@import "./privacy-terms.scss";
@import "./property-manager.scss";
@import "./renter.scss";

@import url("https://fonts.googleapis.com/css2?family=Be+Vietnam:wght@400;500;600;700&display=swap");

html,
body {
  position: relative;
  height: 100%;
  scroll-behavior: smooth;
}

body {
  font-family: "Be Vietnam", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: normal;
  font-size: 17px;
}
@font-face {
  font-family: "jenna_sueregular";
  src: url("../../src/static/fonts/jennasue-webfont.woff") format("woff2"),
    url("../../src/static/fonts/jennasue-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
