.about-banner {
  background: url(/assets/img/About/faq-banner.jpg) no-repeat center;
  background-size: cover;
}

.about-image-section {
  background-color: #fafafa;
  margin: 60px 0 0;
  .left-side-wrap {
    @media (max-width: 768px) {
      margin-top: 100px;
      .image-container {
        padding-right: 0px !important;
        .main-image {
          padding-right: 0px;
          padding-left: 20px;
        }
      }
    }
  }
  .image-container {
    z-index: 9;
  }
  @media (max-width: 1200px) {
    margin: 30px 0 0;
  }
  @media (max-width: 768px) {
    margin: 0;
    padding-top: 0px;
  }
  img {
    max-width: 100%;
  }
  .about-team {
    display: flex;
    margin: 40px 0 0;
    padding-top: 40px;
    border-top: rgba(94, 98, 103, 0.1) 1px solid;
    .about-img {
      width: 40px;
      height: 40px;
      margin: 0 15px 0 0;
      opacity: 0.9;
      flex: none;
      overflow: hidden;
      border-radius: 100%;
    }
    img {
      width: 100%;
      height: 100%;
    }
    .about-title {
      opacity: 0.9;
      h5 {
        font-size: 15px;
        font-weight: 600;
        color: #171a1d;
        margin: 0;
      }
      p {
        font-size: 13px;
        color: #5e6267;
      }
    }
  }
  .main-image {
    position: relative;
    z-index: 200;
  }
  .fixed-left-image {
    z-index: 220;
    left: -25px !important;
  }
  .fixed-square-top {
    position: absolute;
    top: -25px;
    left: -25px;
  }
  .fixed-house-image {
    z-index: 220;
    left: -70px;
    bottom: 30px;
    position: absolute;
  }
}

.about-benefit-section {
  .benifit-section {
    background: transparent;
    h3 {
      @media (max-width: 768px) {
        margin-top: 0px;
      }
    }
  }
  .fixed-house-image {
    z-index: 220;
    left: -70px;
    bottom: 30px;
    position: absolute;
    @media (max-width: 540px) {
      width: 300px;
      left: -44px;
      bottom: 0px;
    }
    @media (max-width: 414px) {
      width: 190px;
      left: -33px;
    }
  }
  .about-thumnails {
    margin-top: 60px;
    @media (max-width: 768px) {
      display: none;
    }
    img {
      @media (max-width: 1200px) {
        width: 100%;
      }
    }
    .about-thumb {
      &:nth-child(2) {
        text-align: center;
      }
      &:last-child {
        text-align: right;
      }
    }
    .fixed-square-image {
      right: -15px;
      bottom: -30px;
      width: auto;
      z-index: -1;
      position: absolute;
    }
    .main-image {
      @media (max-width: 768px) {
        padding-right: 0px;
      }
    }
  }
}
