@import "../../theme/variables.scss";

.chat-container {
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 9999;
  .ui.message {
    padding: 22px 36px !important;
    box-shadow: none !important;
    border-radius: 0 !important;
    span {
      color: $purple-primary;
      cursor: pointer;
      &:hover {
        color: $purple-light;
      }
    }
    .star.icon {
      color: $gold;
    }
    &.cookies-alert {
      background-color: $light-blue;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      display: none;
    }
  }
}
