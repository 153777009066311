.form-group {
  margin-bottom: 20px;
  position: relative;
  .input-with-icon{
    .info-icon{
      position: absolute;
      right: 15px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}
.form-row {
  margin: 0px;
  display: flex;
  justify-content: space-between;
  .form-group {
    width: 48%;
    @media (max-width: 768px) {
      width: 100%;
    }
  }
}
label {
  color: $input-label-color;
  margin: 0;
  font-size: 13px;
  font-weight: 500;
  line-height: normal;
  position: absolute;
  top: 15px;
  left: 20px;
}
.invalid-feedback {
  font-size: 13px;
  font-weight: 500;
  line-height: normal;
  color: $error-color;
}
.form-control {
  background: $input-background;
  border-color: $input-border-color;
  border-radius: 3px;
  box-shadow: 0 11px 14px -10px #e5eced;
  width: 100%;
  height: 75px;
  color: $input-text;
  padding: 34px 20px 20px;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  &::-webkit-input-placeholder,
  &::-moz-placeholder,
  &:-ms-input-placeholder,
  &::-ms-input-placeholder,
  &::placeholder {
    color: #171a1d;
  }

  .was-validated .form-control:invalid,
  &.is-invalid {
    border-color: $error-color;
    background-image: none;
    &:focus {
      border-color: $error-color;
      box-shadow: 0 0 0 0.2rem rgba($error-color, 0.25);
    }

    ~ .invalid-feedback,
    ~ .invalid-tooltip {
      display: block;
    }
  }
}
.input-group-text {
  border: 0;
  background: $input-background;
  font-size: 1.5rem;
}
.icon-tick {
  font-size: 74px;
  margin-bottom: 40px;
}

.select2-container,
.select2-container--default {
  border-color: $input-border-color;
  width: 100%;
  height: 60px;
  border-radius: 4px;
  color: $input-text;
  &:focus {
    color: $input-text;
    background-color: $dropdown-background;
    border-color: $input-focus-border-color;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba($input-focus-border-color, 0.25);
  }
  .select2-selection {
    &--single {
      border-color: $input-border-color;
      background: $dropdown-background;
      height: 60px;
      &:focus {
        outline: 0;
      }
    }
    &__arrow {
      height: 60px !important;
      width: 50px !important;
      b {
        border: 0;
        border-color: transparent !important;
        margin: -14px 0 0 -12px !important;
        &::before {
          font-family: "infront icon";
          content: "\e906";
        }
      }
    }
    &__rendered {
      color: $input-text !important;
      line-height: 60px !important;
      padding-left: 20px !important;
      padding-right: 50px !important;
    }
  }
  .select2-search {
    &__field {
      height: 40px;
      border-color: $input-border-color;
      &:focus {
        color: $input-text;
        background-color: $dropdown-background;
        border-color: $input-focus-border-color;
        outline: 0;
        box-shadow: 0 0 0 0.2rem rgba($input-focus-border-color, 0.25);
      }
    }
  }
  &--open {
    .select2-dropdown {
      &--below {
        border-color: $input-border-color;
      }
    }
    .select2-selection--single {
      .select2-selection__arrow b {
        &::before {
          font-family: "infront icon";
          content: "\e907";
        }
      }
    }
  }
  .select2-results {
    &__option {
      padding: 10px 6px;
      &--highlighted[aria-selected] {
      }
    }
  }
}
.form-control,
input {
  &::-webkit-input-placeholder,
  &::-moz-placeholder,
  &:-ms-input-placeholder,
  &::-ms-input-placeholder,
  &::placeholder {
    color: #5e6267;
    opacity: 1;
  }
  &:focus {
    border-color: #374db1;
    box-shadow: unset;
  }
  &.is-invalid {
    &:focus {
      box-shadow: unset;
    }
  }
}

.search-with-icon {
  position: relative;
  .input-icon {
    position: absolute;
    left: 5px;
    top: 9px;
    &.price {
      top: 7px;
    }
    &.rental {
      left: 10px;
    }
    &.adult {
      left: 10px;
    }
  }
  .form-control,
  input,
  .dropdown {
    background-color: transparent !important;
  }
  .form-control input,
  input.form-control,
  .dropdown {
    padding-left: 32px !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}
