.signup-tabs {
  border-radius: 3px;
  box-shadow: 0 11px 14px -10px #e5eced;
  background-color: $input-background;
  height: 60px;
  margin-bottom: 50px;
  counter-reset: my-sec-counter;
  ul {
    display: flex;
    justify-content: space-between;
    list-style: none;
    height: 100%;
    align-items: center;
    padding: 0px 5px;
    li {
      width: 33.33%;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      .tab-text {
        font-size: 12px;
        font-weight: normal;
        color: #3b1c5a;
        margin-left: 11px;
        height: 20px;
        color: #1a1f27;
      }
      .counter {
        width: 18px;
        height: 18px;
        font-size: 10px;
        font-weight: 800;
        background-color: rgba(94, 98, 103, 0.15);
        display: block;
        text-align: center;
        border-radius: 50%;
        color: #171a1d;
        &::before {
          counter-increment: my-sec-counter;
          content: counter(my-sec-counter);
        }
      }
      &.active-tab {
        .tab-text {
          font-weight: 500;
          color: #3b1c5a;
        }
        .counter {
          background-image: linear-gradient(96deg, #3b1c5a, #374db1 163%);
          color: $white;
        }
      }
    }
  }
}
.next-btn {
  margin-top: 29px;
}
.termspolicy-txt {
  font-size: 14px;
  font-weight: 500;
  color: #5e6267;
  a {
    color: #3b1c5a;
  }
}
.custom-date-dropdown {
  .form-control {
    display: flex;
    padding: 40px 20px 60px;
    .field {
      width: calc(33.33% - 5px);
      margin-right: 5px;
      .ui.selection.dropdown {
        min-width: auto;
        width: 100%;
      }
    }
  }
}

.next-step {
  display: flex;
  justify-content: space-between;
  .next-btn {
    margin-top: 0px;
    color: #374db1;
  }
}
